
import React, { useState, useRef, useEffect } from 'react'
import { Modal } from '../Modal/Modal';
import { ModalBody } from '../Modal/ModalBody';
import { Button, Typography, Grid, Divider, CircularProgress } from '@mui/material';
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'
import { CustomSnackbar } from '../CustomSnackbar/CustomSnackbar';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop, convertToPixelCrop, } from 'react-image-crop'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import CropIcon from '@mui/icons-material/Crop';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import PreviewIcon from '@mui/icons-material/Preview';
import imglyRemoveBackground from "@imgly/background-removal"
import logoheader from '../../../assets/image/homero.gif'
import 'react-image-crop/dist/ReactCrop.css'
import { useIdentificacionStore } from '../../../hooks/useIdentificacionStore';
import { LocalStorageKey } from '../../../types';
import { readLocalStorage } from '../../../toolbox/helpers/local-storage-helpers';
import { useAuthStore } from '../../../hooks';
import { Role } from '../../../types/roles/roleTypes';

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}


export const ModalCrop = (props) => {
    const { open, closeModal, typeImage, data, setData, onList, rut } = props;

    const { user } = useAuthStore()
    const { status: statusIdentify, updateOrCreateIdentify } = useIdentificacionStore()

    const [ nameFile, setNameFile ]       = useState<string>('')
    const [ currentFile,  setCurrentFile ] = useState<any>(null)

    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const hiddenAnchorRef = useRef<HTMLAnchorElement>(null)
    const blobUrlRef = useRef('')
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState<number | undefined>(16 / 9)

    /* --------------------------------------- */
    const [processedImage, setProcessedImage] = useState<string | null>(null);
    const [imageWithoutBackground, setImageWithoutBackground] = useState<any>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingGif, setLoadingGif] = useState<boolean>(false);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const userData = readLocalStorage(LocalStorageKey.USER_DATA)
    const idcompany = userData?.data?.idcompany

    useEffect(()=>{
        if(typeImage){
            switch (typeImage) {
                case 'dni-front':
                    setData({...data, front_identification: '' })
                    break;
                case 'dni-back':
                    setData({...data, reverse_identification: '' })
                    break;
                case 'firma':
                    setData({...data, identification_signature: '' })
                    break;
                case 'photo':
                    setData({...data, person_identification: '' })
                break;
                default:
                    break;
            }
        }
    }, [])

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {

            const files = e.target && e.target.files || [];
            const currentImage = files[0];

            if(currentImage){

                if(currentImage.size && currentImage.size > 2048000){
                    return CustomSnackbar("error", "El tamaño de la imagen no puede exceder a 2048000 bytes.")
                }

                const nameAsArray = currentImage.name.split(".") || [];
                const extension = nameAsArray.slice(-1);

                if(extension[0] == 'jpg' || extension[0] == 'JPG' || extension[0] == 'jpeg' || extension[0] == 'JPEG' ||
                    extension[0] == 'png' || extension[0] == 'PNG'){

                    setCurrentFile(currentImage)
                    setNameFile(currentImage.name)
    
                    setCrop(undefined) 
                    const reader = new FileReader()
                    reader.addEventListener('load', () =>
                        setImgSrc(reader.result?.toString() || ''),
                    )
                    reader.readAsDataURL(e.target.files[0])

                }else{
                    return CustomSnackbar("error", "Solo se aceptan imágenes.")
                }

            }

        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    function onDownloadCropClick() {
        if (!previewCanvasRef.current) {
            throw new Error('Crop canvas does not exist')
        }

        previewCanvasRef.current.toBlob((blob) => {
            if (!blob) {
                throw new Error('Failed to create blob')
            }
            if (blobUrlRef.current) {
                URL.revokeObjectURL(blobUrlRef.current)
            }
            
            blobUrlRef.current = URL.createObjectURL(blob)
            hiddenAnchorRef.current!.href = blobUrlRef.current
            hiddenAnchorRef.current!.click()
        })
    }

    function onSaveCropClick() {
        if (!previewCanvasRef.current) {
            throw new Error('Crop canvas does not exist')
        }

        previewCanvasRef.current.toBlob((blob) => {
            if (!blob) {
                CustomSnackbar("warning", "No ha seleccionado ninguna imagen.")
                throw new Error('Failed to create blob')
            }

            if (blob) {
                const imageForm = new File([blob], 'image.png', { type: 'image/png' })
                switch (typeImage) {
                    case 'dni-front':
                        setData({...data, front_identification: imageForm})
                        break;
                    case 'dni-back':
                        setData({...data, reverse_identification: imageForm})
                        break;
                    case 'firma':
                        if(imageWithoutBackground){
                            setData({...data, identification_signature: imageWithoutBackground})
                        }else{
                            setData({...data, identification_signature: imageForm})
                        }
                        break;
                    case 'photo':
                        setData({...data, person_identification: imageForm})
                    break;
                    default:
                        break;
                }
                if (typeImage !== 'signature') {
                    handleSubmit(imageForm)
                } else {
                    handleSubmit(imageWithoutBackground ? imageWithoutBackground : imageForm)
                }
            }
            
        })
    }

    useDebounceEffect(async () => {
        if (
            completedCrop?.width &&
            completedCrop?.height &&
            imgRef.current &&
            previewCanvasRef.current
        ) {
            // We use canvasPreview as it's much faster than imgPreview.
            canvasPreview(
                imgRef.current,
                previewCanvasRef.current,
                completedCrop,
                scale,
                rotate,
            )
        }
    },100,[completedCrop, scale, rotate],)

    function handleToggleAspectClick() {
        if (aspect) {
            setAspect(undefined)
        } else if (imgRef.current) {
            const { width, height } = imgRef.current
            setAspect(16 / 9)
            const newCrop = centerAspectCrop(width, height, 16 / 9)
            setCrop(newCrop)
            // Updates the preview
            setCompletedCrop(convertToPixelCrop(newCrop, width, height))
        }
    }

    const handleButtonClick = () => {
        if (rotate === 270) {
            setRotate(0);
        } else {
            setRotate((prevNumber) => prevNumber + 90);
        }
    };

    const handleRemoveBackground2 = async () => {
    
        try {

            if (!previewCanvasRef.current) {
                throw new Error('Crop canvas does not exist')
            }

            let imageForm:any;
    
            previewCanvasRef.current.toBlob(async(blob) => {
                if (!blob) {
                    CustomSnackbar("warning", "No ha seleccionado ninguna imagen.")
                    throw new Error('Failed to create blob')
                }
    
                if (blob) {
                    const formData = new FormData()
                    formData.append('image', blob, nameFile ? nameFile : 'cropped-image.jpg')
                    imageForm = formData.get('image')


                    setLoading(true)
                    const imageBuffer = await imageForm.arrayBuffer();

                    setTimeout(() => {
                        setLoadingGif(true);
                    }, 2000);
            
                    imglyRemoveBackground(imageBuffer).then((blob: Blob) => {
                
                        setLoading(false)
                        setLoadingGif(false)
                        const url = URL.createObjectURL(blob);

                        const fileImage = new File([blob], nameFile ? nameFile : 'without-background-image.jpg', { type: blob.type });

                        setImageWithoutBackground(fileImage);
                        setProcessedImage(url);
                    });

                }
                
            })

        } catch (error) {
          console.error('Error removing background:', error);
        }
    };

    const handleSubmit = async (image:File|null) => {
        setLoadingSave(true);
        if (!image) { setLoadingSave(false); return}
        const payload = new FormData()
        switch (typeImage) {
            case 'front':
                payload.append('front_identification', image)
                break;

            case 'reverse':
                payload.append('reverse_identification', image)
                break;

            case 'signature':
                payload.append('identification_signature', image)
                break;
            
            case 'person':
                payload.append('person_identification', image)
                break;
        }
        payload.append('idcompany', idcompany)
        if (user.userType == Role.ADMIN) {
            payload.append('rut', rut)
        }
        const response = await updateOrCreateIdentify(payload)
        if (response === true) {
            setLoadingSave(false)
            closeModal()
            onList()
        }
        setLoadingSave(false);
    }

    
    const handleRemoveBackground = async (image:File|null) => {
        if (!image) return;
        try {
            setLoading(true)
            const imageBuffer = await image.arrayBuffer()
            imglyRemoveBackground(imageBuffer).then((blob: Blob) => {
                if (blob) {
                    const file = new File([blob], 'signature.png', { type: 'image/png' });
                    const payload = new FormData()
                    payload.append('identification_signature', file)
                    setLoading(false)
                    updateOrCreateIdentify(payload)
                    closeModal()
                    onList()
                }
            });
        } catch (error) {
          console.error('Error removing background:', error);
        }
      };

    return (
        <>
        <Modal open={open} disableEscapeKeyDown disableBackdropClick size='md'>
            <ModalBody>

                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                    <Grid item xs={8}>
                        <Button 
                        variant="contained"
                        component="label"
                        style={{ maxWidth: '100%', width: '100%' }}
                        sx={{ boxShadow: 'none', textTransform: 'none', color: '#fff', background: '#212D39', "&:hover": {backgroundColor: "#212D39" } }}        
                        >
                        <FileUploadIcon fontSize="small" />
                            {nameFile ?  nameFile : (
                                typeImage == 'front' ? 'Subir DNI Frontal' : (typeImage == 'reverse' ? 'Subir DNI Reverso' : (typeImage == 'signature' ? 'Subir Firma' : (typeImage == 'person' ? 'Subir foto de identificación' : 'subir Imágen') ) )
                            )}
                        <input
                            style={{ display: 'none' }}
                            type='file'
                            name='file'
                            onChange={onSelectFile}
                        />
                        </Button>
                    </Grid>
                </Grid>
                
                {
                    !!imgSrc && (
                        <>
                        <Grid xs={12} display='flex' justifyContent='center' sx={{marginBottom:'10px'}}>
                            <Button variant="contained" endIcon={<Rotate90DegreesCwIcon />} onClick={handleButtonClick} 
                                sx={{marginRight:'2px', height: '30px'}}
                            >
                                Rotar
                            </Button>
                            <Button variant="contained" endIcon={<CropIcon />} onClick={handleToggleAspectClick} 
                                sx={{marginLeft:'2px', height: '30px'}}
                            >
                                Alternar aspecto {aspect ? 'off' : 'on'}
                            </Button>
                        </Grid>
                        </>
                    )
                }

                <Grid xs={12} display='flex' justifyContent='center' sx={{border:'solid 1px #b6b4b4', borderRadius:'20px', padding:'10px', marginBottom:'10px'}}>
                    {!!imgSrc && (
                        <ReactCrop
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={aspect}
                        >
                        <img
                            ref={imgRef}
                            alt="Crop me"
                            src={imgSrc}
                            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                            onLoad={onImageLoad}
                            width='400px'
                        />
                        </ReactCrop>
                    )}
                    {
                        !imgSrc && (
                        <AddPhotoAlternateIcon sx={{fontSize: '100px', color:'#b6b4b4'}}/>
                        )
                    }
                </Grid>

                <Grid xs={12} display='flex' justifyContent='center' sx={{border:'solid 1px #b6b4b4', borderRadius:'20px', padding:'10px', marginBottom:'10px'}}>
                    {
                        !!completedCrop && (
                            <canvas
                            ref={previewCanvasRef}
                            style={{
                                border: '1px solid black',
                                objectFit: 'contain',
                                width: completedCrop.width,
                                height: completedCrop.height,
                                display: 'flex',
                                textAlign: 'center'
                            }}
                            />
                        )
                    }
                    {
                        !completedCrop && (
                        <PreviewIcon sx={{fontSize: '100px', color:'#b6b4b4'}}/>
                        )
                    }
                </Grid>
                {
                    typeImage == 'signature' && !!completedCrop && (
                        <>
                        <Grid xs={12} display='flex' justifyContent='center' marginBottom='10px'>
                            <button onClick={handleRemoveBackground2}>Remover fondo de la imagen</button>
                        </Grid>
                        {
                            loading && (
                                <>
                                <Grid xs={12} display='flex' justifyContent='center' marginBottom='10px'>
                                    <Typography>La imagen esta siendo procesada, espere un momento.</Typography>
                                </Grid>
                                {
                                    loadingGif && (
                                        <Grid xs={12} display='flex' justifyContent='center' marginBottom='10px'>
                                            <img src={logoheader} alt="GIF animado" />
                                        </Grid>
                                    )
                                }
                                </>
                            )
                        }
                        {
                            !loading && processedImage && (
                                <Grid xs={12} display='flex' justifyContent='center' sx={{border:'solid 1px #3ed312', borderRadius:'20px', padding:'10px', marginBottom:'10px'}}>
                                    <img 
                                        src={processedImage} 
                                        alt="Processed" 
                                        width= {completedCrop.width || '300px'}
                                        height= {completedCrop.height || '300px'}
                                        style={{ border: `1px solid black` }}
                                    />
                                </Grid>
                            )
                        }
                        </>
                    )
                }

                <Divider/>

                <Grid container sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        {
                            !!completedCrop && (
                                <Button
                                    onClick={() => onSaveCropClick()}
                                    sx={{ backgroundColor: '#212D39', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#212D39" } }}
                                >
                                    Guardar
                                    {
                                        loadingSave && (
                                        <CircularProgress color="inherit" size={12} sx={{ color: '#fff', marginLeft: "10px" }} />
                                        )
                                    }
                                </Button>
                            )
                        }
                        <Button
                            onClick={() => closeModal()}
                            sx={{ backgroundColor: '#212D39', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#212D39" } }}
                        >
                            Cancelar
                        </Button>
                    </Grid>
                </Grid>
            </ModalBody>
        </Modal>
        </>
    )
}