import { FC, useState } from "react";
import { Grid, ButtonGroup, IconButton, Typography, Card, CardContent, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { useOwnerStore } from "../../hooks/useOwnerStore";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { useReportStore } from "../../hooks/useReportStore";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { FilterComponent } from "./components/FilterComponent";
import { moneyFormat } from "../../toolbox/helpers/money-helpers";
import { NotData } from "./components/NotData";
import { ReportAPI } from "../../apis/ReportAPI";

const columns = [
    { type: 'text', field: 'description_report', label: 'DESCRIPCIÓN', align: 'center' },
    { type: 'text', field: 'namedepartment', label: 'DEPARTAMENTO', align: 'center' },
    { type: 'text', field: 'amount_debt', label: 'TOTAL A PAGAR', align: 'center' , 
    format: (row) => moneyFormat(row.amount_debt, '$')},
    { type: 'text', field: 'amount_payable', label: 'TOTAL PAGADO', align: 'center',
    format: (row) => moneyFormat(row.amount_payable, '$') },
    { type: 'text', field: 'amount_balance', label: 'BALANCE', align: 'center',
    format: (row) => moneyFormat(row.amount_balance, '$') },
]

export const ReportAdminRenditionPayment: FC = () => {

    const { status: statusApiDepartment } = useDepartmentStore()
    const { status: statusOwnerApi } = useOwnerStore()

    const { getReportRenditionPay, dataReportRenditionPayment: data, filter, building, owner, year, month, status } = useReportStore();
    const [params, setParams] = useState<any>();

    const downloadExcel = async () => {
        const response  = await ReportAPI.getReportRenditionPayExcel(params);
        
        if(response.status){
            const btn_excel = document.createElement('a');
            btn_excel.href = `${response.link}`
            document.body.appendChild(btn_excel);
            btn_excel.click();
            document.body.removeChild(btn_excel)
        }

    }

    const onListRenditionPay = () => {
        let dataParams = {} 
        switch (filter) {
            case '01':
                dataParams = {
                    "month": month.substring(month.length - 2),
                    "year": month.substring(0, 4),
                    "idbuilding": building.id,
                    "idcompany": 1
                }
                getReportRenditionPay(dataParams)
                setParams(dataParams)
                break;
            case '02':
                dataParams = {
                    "year": year?.name,
                    "idbuilding": building.id,
                    "idcompany": 1
                }
                getReportRenditionPay(dataParams)
                setParams(dataParams)
                break;
            case '03':
                dataParams = {
                    "month": month.substring(month.length - 2),
                    "year": month.substring(0, 4),
                    "idowner": owner.id,
                    "idcompany": 1
                }
                getReportRenditionPay(dataParams)
                setParams(dataParams)
                break;
            case '04':
                dataParams = {
                    "year": year?.name,
                    "idowner": owner.id,
                    "idcompany": 1
                }
                getReportRenditionPay(dataParams)
                setParams(dataParams)
                break;
            case '05':
                dataParams = {
                    "month": month.substring(month.length - 2),
                    "year": month.substring(0, 4),
                    "idowner": owner.id,
                    "idbuilding": building.id,
                    "idcompany": 1
                }
                getReportRenditionPay(dataParams)
                setParams(dataParams)
                break;
            case '06':
                dataParams = {
                    "year": year?.name,
                    "idowner": owner.id,
                    "idbuilding": building.id,
                    "idcompany": 1
                }
                getReportRenditionPay(dataParams)
                setParams(dataParams)
                break;
            default:
                break;
        }
    }

    return (
        <>

            {statusApiDepartment === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            {statusOwnerApi === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            {status === ApiStatus.FETCHING && <CustomBackdrop open={true} />}

            <Grid container>
                <Grid item container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '10px' }}>
                    <Typography variant='h6' component='h1' gutterBottom color='#808080' className="tittle" sx={{ mt: 1, ml: 1, fontWeight: 600 }}>
                        {('Reporte de Pago de Rendiciones').toUpperCase()}
                    </Typography>
                </Grid>
                <FilterComponent submit={onListRenditionPay} />
                {
                   data &&  data.data_department ? 
                   <>
                        <Grid item xs={12} sx={{ padding: 2 }}>
                            {
                                (data.data_department || []).map((dataDepartment)=>{
                                    return (
                                        <Grid xs={12} item container display='flex' justifyContent='center' alignItems='center'>

                                            <Card sx={{margin:'5px', width:'200px'}}>
                                                <CardContent>
                                                    <Typography variant="h6" component="div" align="center">
                                                    {'Total a Pagar'}
                                                    </Typography>
                                                    <Typography variant="h5" component="div" align="center">
                                                    {moneyFormat(dataDepartment?.total_debt, '$')}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                            <Card sx={{margin:'5px', width:'200px'}}>
                                                <CardContent>
                                                    <Typography variant="h6" component="div" align="center">
                                                    {'Total pagado'}
                                                    </Typography>
                                                    <Typography variant="h5" component="div" align="center">
                                                    {moneyFormat(dataDepartment?.total_payable, '$')}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                            <Card sx={{margin:'5px', width:'200px'}}>
                                                <CardContent>
                                                    <Typography variant="h5" component="div" align="center">
                                                    {'Deuda'}
                                                    </Typography>
                                                    <Typography variant="h5" component="div" align="center">
                                                    {moneyFormat((dataDepartment?.total_balance ? Math.abs(dataDepartment?.total_balance) : dataDepartment?.total_balance), '$')}
                                                    </Typography>
                                                </CardContent>
                                            </Card>

                                            <CustomTable
                                                title={''}
                                                columns={columns}
                                                rows={dataDepartment.data_pay || []}
                                                onRowClick={() => { }}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </>
                    : <NotData/>
                }

                {
                    data && ((data?.data_department) && (data?.data_department).length) == 0 && (<NotData/>)
                }

            </Grid>
        </>
    )
}