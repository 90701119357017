
import { Typography, Grid } from "@mui/material";
import { Modal } from "../Modal/Modal";
import { ModalHeader } from "../Modal/ModalHeader";
import { ModalBody } from "../Modal/ModalBody";
import { ModalFooter } from "../Modal/ModalFooter";
import { ApiStatus } from "../../../types/api/status";
import { CustomBackdrop } from "../CustomBackdrop/CustomBackdrop";
// import QRCode from 'qrcode';
import { useEffect, useState } from "react";


export const ModalQr: React.FC<any> = (props): JSX.Element | any => {
    const { open, title, closeModal, clearState, data, status2=false } = props;

    const [imagValue, setImgValue] = useState<string>("");

    useEffect(()=> {
        generateqr();
    }, [])

    const generateqr = async () => {
        if(data){
            // const data_qr = await QRCode.toDataURL(data.qr);
            // setImgValue(data_qr)
        }
    }

    const handleDownloadImage = () => {
        let tag:any = document.getElementById("qr-id");
        tag.click();
    }
  
    return (
      <>

      <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">

        <ModalHeader
          text={title || 'Código QR'}
          className='positionElements'
          onCancel={closeModal}
          clearState={clearState}
        >
        </ModalHeader>

        <ModalBody>
          <Typography variant="subtitle1" className="textColor"> 
            <Grid textAlign="center" justifyContent="center">
                {
                    data ? (
                        <>
                        <Typography sx={{color:'#101010', fontSize:'14px'}}>QR de asistencia al departamento</Typography>
                        <Grid sx={{padding: "10px"}}>
                            {
                                imagValue ? (
                                    <a href={imagValue} download={'qr'} id="qr-id" >
                                        <img src={imagValue} alt="QR" style={{minWidth: "250px", minHeight: "250px"}}/>
                                    </a>
                                ) : (<Typography sx={{color:'#101010', fontSize:'14px'}}>ocurrió algún problema</Typography>)
                            }
                            
                        </Grid>
                        </>
                    ) : (
                        <Grid sx={{padding: "10px"}}>
                            <Typography sx={{color:'#101010', fontSize:'14px'}}>ocurrió algún problema</Typography>
                        </Grid>
                    )
                }
                
            </Grid>
          </Typography>
        </ModalBody>

        <ModalFooter 
          confirmText={"Descargar"}
          onConfirm={handleDownloadImage}
        //   secondaryText={"Imprimir"}
        //   onSecondaryConfirm={onSecondaryConfirm}
          className='modal-confirm-footer'
          clearState={clearState}
        //   loadingConfirmText={status2}
        />
      </Modal>
      </>
    )
}